@import 'colors.module.scss';

.template {
  width: 92%;
  padding: 0 4%;
  background: $backgroundGradientPrimary;
  background-size: 400% 400%;
  min-height: 100vh;

  .templateheader {
    position: sticky;
    top: 0;
    z-index: 100;
    margin-bottom: 0.4rem;
  }

  .banner {
    width: 100%;
    background-image: url(./images/banner.jpeg);
    height: 30rem;
    background-position: center;
  }

  .divider {
    margin: 1rem 0 0;
  }

  .content {
    display: flex;
    text-align: center;
    .page {
      flex: 3;
      padding: 0 2rem;
    }
    .sidebar {
      flex: 1;
      padding: 1.25rem;
    }
  }

  .footer {
    background: $backgroundGradientSecondary;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
    .ant-typography {
      color: $textPrimary;
    }
    a {
      color: $backgroundPrimary;
    }
    > div {
      padding: 0 1.25rem;
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }

  .copyright {
    background: $backgroundGradientSecondary;
    color: $backgroundPrimary;
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }
}

.mobile {
  .content {
    .page {
      padding: 0 1rem;
    }
  }

  .footer {
    flex-direction: column;
  }

  .footer div {
    text-align: center;
  }
}
