$backgroundPrimary: #fff;
$backgroundSecondary: #fff;
$backgroundGradientPrimary: linear-gradient(
  -45deg,
  #ee7752,
  #e73c7e,
  #23a6d5,
  #23d5ab
);
$backgroundGradientSecondary: linear-gradient(
  -45deg,
  #23d5ab,
  #23a6d5,
  #e73c7e,
  #ee7752
);
$textPrimary: rgba(0, 0, 0, 0.88);
$textSecondary: rgba(0, 0, 0, 0.88);
$linkPrimary: #23a6d5;
$linkSecondary: #23a6d5;
$linkActivePrimary: #23d5ab;
$linkActiveSecondary: #23d5ab;
$headingPrimary: #ee7752;
$headingSecondary: #ee7752;
$highlightPrimary: #23a6d5;
$highlightSecondary: #23a6d5;
$borderColor: #e8e8e8;

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  backgroundGradientPrimary: $backgroundGradientPrimary;
  backgroundGradientSecondary: $backgroundGradientSecondary;
  textPrimary: $textPrimary;
  textSecondary: $textSecondary;
  linkPrimary: $linkPrimary;
  linkSecondary: $linkSecondary;
  linkActivePrimary: $linkActivePrimary;
  linkActiveSecondary: $linkActiveSecondary;
  headingPrimary: $headingPrimary;
  headingSecondary: $headingSecondary;
  highlightPrimary: $highlightPrimary;
  highlightSecondary: $highlightSecondary;
}
