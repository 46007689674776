@import '../../colors.module.scss';

.chapters {
  margin-left: 0.5rem;
}

.book {
  margin: 0 0 1.5rem 1.5rem;
  .bookNav {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;

    .arrow {
      color: $linkPrimary;
    }
    .booktitle {
      color: $headingPrimary !important;
      b {
        color: $headingPrimary !important;
      }
    }
  }
}

.mobile .chapters {
  flex: 0 0 0 !important;
  width: auto !important;
  min-width: 0 !important;
  max-width: none !important;
  margin-right: 1.5rem;
}
