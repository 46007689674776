.news {
  .date {
    text-align: right;
  }
  .gallery {
    width: 100%;
    padding: 0 10%;
    .image-gallery-thumbnail {
      width: 12% !important;
    }
  }
}
