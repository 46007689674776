@import '../../colors.module.scss';
.news {
  text-align: left;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $borderColor;
  .link {
    color: $linkPrimary;
    padding-left: 5px;
  }
}
