@import '../../colors.module.scss';

.header {
  display: flex;
  .logo {
    color: $textPrimary !important;
    text-decoration: none !important;
    font-family: 'cinzel';
    font-size: 1.5rem;
    max-width: 200px;
    min-width: 100px;
  }
  .menu {
    flex: auto;
    justify-content: end;
    width: 6rem;
  }
}
