.contact {
  display: flex;
  margin: 1.5rem 0 2.5rem;
  > div {
    flex: 1;
    padding: 0 1.5rem;
  }
  img {
    width: 100%;
  }
  .map {
    border: 0;
    width: 100%;
    height: 100%;
  }
}

.mobile {
  .contact {
    display: flex;
    flex-direction: column;
  }
}
